import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import ListItemIcon from '@mui/material/ListItemIcon' 
import AddIcon from '@mui/icons-material/Add'
import moment from 'moment'

import _Base from '../_Base'
import AppState from '../../managers/AppStateManager'
import { C_EVENT_IDS, C_MODULE_NAME, C_STATUS_MAP_ICON, C_DEFAULTS } from '../../variables/common'
import Util from '../../utils/Util'

const styles = theme => ({
    root: {
        fontSize: '100%',
    },
    topPanel: {
        background: 'white',
        height: 140,
        position: 'fixed',
        width: 248,
        zIndex: 2,
    },
    topPanelSpacer: {
        height: 140,
    },
    flexItemRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    toolTip: {
        backgroundColor: '#fec32d',
        color: 'black',
        padding: 10,
        fontSize: '1.2em',
        opacity: 1,
    },
    button: {
        margin: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        flex: 1,
    },
    dense: {
        marginTop: 16,
    },
    toolbar: theme.mixins.toolbar,
    selectEmpty: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginRight: 10,
        marginLeft: 10,
        marginBottom: 10,
    },
    listItemText: {
        paddingLeft: 0,
    },
    listItemPrimaryText: {
        fontWeight: 500,
    },
    listItemSecondaryText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 300,
    }
})

const sortOptionsMap = {
    0: { display: 'Sort Last Modified Date Ascending', value: { field: 'lastModifiedDate', order: 'asc' } },
    1: { display: 'Sort Last Modified Date Descending', value: { field: 'lastModifiedDate', order: 'desc' } },
    2: { display: 'Sort Event Date Ascending', value: { field: 'datetime', order: 'asc' } },
    3: { display: 'Sort Event Date Descending', value: { field: 'datetime', order: 'desc' } },
    5: { display: 'Sort Event Name Ascending', value: { field: 'name', order: 'asc' } },
    6: { display: 'Sort Event Name Descending', value: { field: 'name', order: 'desc' } },
    7: { display: 'Sort Event Status Ascending', value: { field: 'status', order: 'asc' } },
    8: { display: 'Sort Event Status Descending', value: { field: 'status', order: 'desc' } },
    9: { display: 'Sort Human Classification Ascending', value: { field: 'humanClassification', order: 'asc' } },
    10: { display: 'Sort Human Classification Descending', value: { field: 'humanClassification', order: 'desc' } },
}

class EventList extends _Base {

    _applySearchText = () => {
        const { searchText } = this.state
        let tmpList = this.eventList
        if (searchText) {
            tmpList = this.eventList.filter((e) => {
                const textString = moment.utc(e.datetime).format('MM/DD/YYYY HH:mm:ss.SSS') + ' ' + e.name + " " + e.humanClassification
                return textString.toLowerCase().indexOf(searchText) !== -1
            })
        }
        return tmpList
    }

    searchEvent = () => {
        const { searchText } = this.state
        if (searchText) {
            this.eventList = this._applySearchText()
            this.forceUpdate()
        }
    }

    handleOnSearchChange = event => {
        Util.debounce((target) => {
            let searchText = target.value
            this.eventList = Util.cloneDeep(AppState.eventList) 
            if (searchText.trim().length === 0) {
                this.setState({
                    searchText: '',
                })     
            } else {
                this.setState({
                    searchText: searchText.toLowerCase()
                }, () => {
                    this.searchEvent(searchText)
                })
            }
        }, 2000)(event.target)
    }

    handleOnSortChange = event => {
        const sortOpt = sortOptionsMap[event.target.value]
        this.setState({ [event.target.name]: event.target.value }, () => {
            AppState.sortEventList(sortOpt.value)
        })
    }

    handleAddButtonClick = (e) => {
        AppState.setAddMode(true, () => {
            AppState.currentEventId = ''
            this.forceUpdate()
        })
    } 

    handleListItemClick = eventId => (e) => {
        // console.log('DEBUG: handleListItemClick', eventId)
        AppState.getEvent(eventId, (res) => {
            if (res.success) {
                AppState.setViewMode(false, () => {
                    AppState.currentEventId = eventId
                    this.forceUpdate()
                })
            } else {
                console.log('DEBUG:error', res.error)
            }
        })
    }

    handleEventListDataChanged = (data) => {
        this.eventList = Util.cloneDeep(AppState.eventList) 
        if (this.state.searchText) {
            this.eventList = this._applySearchText()
        }
        this.forceUpdate()
    }

    handleOnEventDataLoaded = (event) => {
        this.eventList = Util.cloneDeep(AppState.eventList)
        if (this.state.searchText) {
            this.eventList = this._applySearchText()
        }
        Util.debounce(() => {this.forceUpdate()}, 100)()
    }

    handleOnSetViewMode = () => {
        Util.debounce(() => {this.forceUpdate()}, 100)()
    }

    handleOnEventDeleted = (eventId) => {
        let eventList = this.eventList
        let idx = eventList.findIndex((e) => { return e._id === eventId})

        if (idx !== -1) {
            eventList.splice(idx, 1)
            AppState.eventList = eventList
            this.forceUpdate()
        }
    }

    handleExpandList = () => {
        this.setState({ limit: this.state.limit + 30})
    }

    constructor(props) {
        super(props)
        this.eventList = Util.cloneDeep(AppState.eventList) 
        this.state = {
            show: false,
            sort: 3,
            limit: 30,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        AppState.addEventListener(C_EVENT_IDS.C_ON_EVENT_LIST_DATA_CHANGED, this.handleEventListDataChanged)
        AppState.addEventListener(C_EVENT_IDS.C_ON_SET_VIEW_MODE, this.handleOnSetViewMode)
        AppState.addEventListener(C_EVENT_IDS.C_ON_EVENT_DELETED, this.handleOnEventDeleted)
        AppState.addEventListener(C_EVENT_IDS.C_ON_EVENT_DATA_LOADED, this.handleOnEventDataLoaded)
    }

    componentWillUnmount() {
        AppState.removeEventListener(C_EVENT_IDS.C_ON_EVENT_LIST_DATA_CHANGED, this.handleEventListDataChanged)
        AppState.removeEventListener(C_EVENT_IDS.C_ON_SET_VIEW_MODE, this.handleOnSetViewMode)
        AppState.removeEventListener(C_EVENT_IDS.C_ON_EVENT_DELETED, this.handleOnEventDeleted)
        AppState.removeEventListener(C_EVENT_IDS.C_ON_EVENT_DATA_LOADED, this.handleOnEventDataLoaded)
    }

    render() {
        const { classes } = this.props
        const { searchText, limit } = this.state
        const eventList = this.eventList
        const noEvents = this._isReady && eventList.length === 0
        const withEvents = eventList.length > 0
        
        return (
            <div className={classes.root}>
                <div>
                    <div className={classes.toolbar} />
                    <div className={classes.topPanel}>

                        <div className={classes.flexItemRow}>
                            <TextField
                                label="Search"
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                variant="outlined"
                                defaultValue={searchText}
                                onChange={this.handleOnSearchChange}
                            />
                            {AppState.currentModuleName === C_MODULE_NAME.C_DATA_ENTRY &&
                                <Tooltip title="Create new event" classes={{ tooltip: classes.toolTip }}>
                                    <Fab size="small" color="primary" aria-label="Add" className={classes.margin} style={{ marginTop: 20 }} onClick={this.handleAddButtonClick}>
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                            }
                        </div>

                        {withEvents &&
                            <div className={classes.flexItemRow}>
                                <Select
                                    variant="outlined"
                                    value={this.state.sort}
                                    onChange={this.handleOnSortChange}
                                    displayEmpty
                                    name="sort"
                                    className={classes.selectEmpty}>
                                    {Object.keys(sortOptionsMap).map(key => (
                                        <MenuItem key={key} value={key}>{sortOptionsMap[key].display}</MenuItem>

                                    ))}
                                </Select>
                            </div>
                        }

                    </div>

                    <div className={classes.topPanelSpacer} />

                    {noEvents &&
                        <div>
                            <div className={classes.toolbar} />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 20 }}>
                                <Typography style={{ fontStyle: 'italic' }}>There are no events...</Typography>
                            </div>
                        </div>
                    }
                    {withEvents &&
                        <List component="nav">
                            {eventList.slice(0, limit).map(event => (
                                <ListItem button
                                    key={event._id}
                                    selected={event._id === AppState.currentEventId}
                                    onClick={this.handleListItemClick(event._id)}
                                >
                                    <ListItemIcon style={C_STATUS_MAP_ICON[event.status].style} title={event.status}>
                                        {C_STATUS_MAP_ICON[event.status].icon}
                                    </ListItemIcon>
                                    <ListItemText className={classes.listItemText}
                                        classes={{ primary: classes.listItemPrimaryText }}
                                        primary={moment.utc(event.datetime).format(C_DEFAULTS.C_DATE_FORMAT_STRING)}
                                        secondary={
                                            <React.Fragment>
                                                <Typography component="span" className={classes.listItemSecondaryText}>
                                                    {event.name}
                                                </Typography>
                                            </React.Fragment>
                                        } />
                                </ListItem>
                            ))}
                            <ListItem>
                                <Button
                                    key="show-more-events"
                                    onClick={this.handleExpandList}
                                >
                                    Show More Events
                                </Button>
                            </ListItem>
                        </List>
                    }
                </div>
            </div>
        );
    }
}

EventList.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EventList)