import React, { useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContentText } from "@mui/material";
import { ZoomIn, ZoomOut, ArrowForward, ArrowBack } from "@mui/icons-material";

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

const PDFViewer = (props) => {

    const { file, classes } = props;
    const pdfRelativePath = `${window.BOLIDES_APP_PDF_SERVER_URL}${file}`;

    const initialZoomScale = 1.5;
    const maxZoomScale = 5.0;
    const minZoomScale = 0.25;
    const deltaZoomScale = 0.5;

    const [currentPage, setCurrentPage] = useState(1);
    const [numPages, setNumPages] = useState();
    const [zoomScale, setZoomScale] = useState(initialZoomScale);
    const [dialogOpen, setDialogOpen] = useState(false);


    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    function onDocumentLoadError(err) {
        console.log(err);
    }

    function handleClickOpen() {
        setDialogOpen(true)
    }

    function handleClose() {
        setDialogOpen(false);
    }

    function nextPage() {
        resetZoom();
        setCurrentPage(Math.min(currentPage + 1, numPages));
    }

    function prevPage() {
        resetZoom();
        setCurrentPage(Math.max(currentPage - 1, 1));
    }

    function handleZoomIn() {
        setZoomScale(Math.min(zoomScale * 1.25, maxZoomScale));
    }

    function handleZoomOut() {
        setZoomScale(Math.max(zoomScale * 0.75, minZoomScale));
    }

    function resetZoom() {
        setZoomScale(initialZoomScale);
    }



    return (
        <div className={classes.flexItemRow}>
            <div style={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                marginLeft: 0
            }}>
                <div style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    fontSize: "0.8rem",
                    marginLeft: 10,
                }}>
                </div>
            </div>
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline"
            }}>
                <div id="control-buttons"
                    style={{
                        marginLeft: "24px",
                        padding: "0px",
                        width: "max-content",
                        height: "100%",
                        backgroundColor: "#efefef",
                        borderRadius: "0px"
                    }}>
                    <span>
                        <Button style={{margin: "8px"}} variant="contained" onClick={resetZoom}>Reset Zoom</Button>
                        <Button style={{margin: "8px"}} variant="contained" onClick={handleZoomOut}>
                            <ZoomOut />
                        </Button>
                        <Button style={{margin: "8px"}} variant="contained" onClick={handleZoomIn}>
                            <ZoomIn />
                        </Button>
                    </span>
                </div>
                
                <Button style={{margin: "8px"}} variant="contained" href={pdfRelativePath} download={file} target="_blank">Download PDF</Button>
                
                <Button style={{margin: "8px"}} variant="contained" onClick={handleClickOpen}>Fullscreen</Button>
            </div>
            <div style={{
                width: "100%",
                height: "65vh",
                overflow: "scroll",
                padding: "4px",
                marginLeft: "24px",
                marginRight: "24px",
                marginBottom: "12px",
                border: "0.5px solid gray",
                borderRadius: "4px"
            }}
            >
                <Document file={pdfRelativePath} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                    {Array.from(new Array(numPages), (el, idx) => (
                        <Page
                            key={`page_${idx + 1}`}
                            pageNumber={idx + 1}
                            scale={zoomScale}
                        />
                    ))}
                </Document>
            </div>
            <Dialog
                fullScreen
                open={dialogOpen}
                onClose={handleClose}
            >
                <DialogTitle>{file}</DialogTitle>
                <DialogContentText style={{margin: "16px"}}>Press ESC to exit</DialogContentText>
                <Document file={pdfRelativePath} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                    {Array.from(new Array(numPages), (el, idx) => (
                        <Page
                            key={`page_${idx + 1}`}
                            pageNumber={idx + 1}
                            scale={zoomScale}
                        />
                    ))}
                </Document>
            </Dialog>
        </div>
    )
};

export default PDFViewer;