import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import RefreshIcon from '@mui/icons-material/Refresh'
import FeedbackIcon from '@mui/icons-material/Feedback'
import ComputerIcon from '@mui/icons-material/Computer';
import DnsIcon from '@mui/icons-material/Dns';
import CreateIcon from '@mui/icons-material/Create';
import Fab from '@mui/material/Fab'
import Chip from '@mui/material/Chip'
import moment from 'moment'
import classNames from 'classnames'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import _Base from '../_Base'
import Util from '../../utils/Util'
import AppState from '../../managers/AppStateManager'
import ButtonProgress from '../../components/ButtonProgress/ButtonProgress'
import FileUpload from '../FileUpload/FileUpload'
import EventFormAttachments from '../EventFormAttachments/EventFormAttachments'
import TextFieldFormat from '../TextFieldFormat/TextFieldFormat'
import TextFieldPatternFormat from '../TextFieldFormat/TextFieldPatternFormat'
import GroupContainer from '../GroupContainer/GroupContainer'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from "@mui/material/ListItemText";
import EventFormView from './EventFormView';
import { generateGroundTrack, GROUND_TRACK_CATEGORIES } from '../../utils/GroundTrack';
import { generateBrightness, BRIGHTNESS_CATEGORIES } from "../../utils/Brightness";
import { getEnergies, getPlatformKey } from "../../utils/Attachments";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    C_EVENT_IDS,
    C_MODULE_NAME,
    C_MODE,
    C_DEFAULTS,
    C_EVENT_STATUS,
    C_STATUS_MAP_ICON
} from '../../variables/common'
import PDFViewer from '../PDFViewer/PDFViewer'

const styles = theme => ({
    root: {
        fontSize: '100%',
        padding: 0,
        margin: 0,
        width: '100%',
        overflowY: 'auto',
        height: '100vh',
        backgroundColor: 'white',
    },
    margin: {
        margin: theme.spacing(1),
    },
    appBar: {
        top: 64,
        marginLeft: C_DEFAULTS.C_DRAWER_WIDTH
    },
    appBarToolbar: {
        marginLeft: C_DEFAULTS.C_DRAWER_WIDTH
    },
    toolbar: theme.mixins.toolbar,
    grow: {
        flexGrow: 1,
    },
    container: {
        padding: '10px 10px 0px 10px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    flexItemRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: 5,
        borderBottom: '1px solid #e0e0e0',
    },
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 8,
    },
    select: {
        //marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 8,
    },
    inputLabel: {
        backgroundColor: 'white',
        paddingLeft: 3,
        paddingRight: 3,
    },
    chip: {
        margin: theme.spacing(1),
    },
    initialStatus: {
        color: '#757575'
    },
    submittedStatus: {
        color: 'blue'
    },
    rejectedStatus: {
        color: 'red'
    },
    publishedStatus: {
        color: 'green'
    },
    rejectConfirmation: {
        width: 500
    },
    reasonPanel: {
        border: '1px solid red',
        borderRadius: 5,
        padding: 5,
        justifyContent: 'flex-start',
        backgroundColor: 'rgba(255,0,0,0.1)',
    },
    formControl: {
        flex: 1,
        alignItems: 'normal',
        marginLeft: 8,
        cursor: 'default',
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.8rem',
    },
    html: {
        scrollBehavior: 'smooth'
    }
})

let fieldsToValidate = [
    'eventDate',
    'eventTime',
    'duration',
    'latitude',
    'longitude',
    'latitudeDelta',
    'longitudeDelta',
    'detectedBy',
    'confidenceRating',
    'lightcurveStructure',
    'nearbyLightningActivity',
    'beginningAltitude',
    'humanClassification'
];

let _humanClassificationCategories = [
    { name: "Bolide", view: true },
    { name: "Lightning", view: true},
    { name: "Large glint artifact", view: true},
    { name: "Small water body glint", view: true},
    { name: "Terrestrial solar farm glint", view: true},
    { name: "Near field satellite glint", view: true},
    { name: "Hot pixel", view: true},
    { name: "Rocket body", view: true},
    { name:"Transient Luminous Events (TLEs)", view: true},
    { name: "Anthropogenic entering object", view: true},
    { name: "Other sensor issue", view: true},
    { name: "Unknown", view: true},
    { name: "Alien spacecraft", view: true}
];

class EventFormEntry extends _Base {

    getFormData = () => {
        let formData = {}
        fieldsToValidate.forEach((f) => {
            let obj = { [f]: this.state[f] }
            formData = Util.assign({}, formData, obj)
        })
        return formData
    }

    areEntriesValid = (cb) => {
        const ret = this.validateEntries()
        const invalidEntriesDetected = Object.keys(ret).some((key) => {
            return ret[key]
        })
        if (invalidEntriesDetected) {
            this.setState(ret, () => {
                cb && cb(false)
            })
        } else {
            cb && cb(true)
        }
    }

    validateEntries = () => {
        const { eventAttachments } = this.state
        let tmpErrorState = {}
        fieldsToValidate.forEach((field) => {
            let obj = { [`${field}Error`]: !this._validateEntry(field, this.state[field]) }
            tmpErrorState = Util.assign({}, tmpErrorState, obj)
        })
        if (eventAttachments.length === 0) {
            tmpErrorState = Util.assign({}, tmpErrorState, {
                eventAttachmentsError: true
            })
        }
        return tmpErrorState
    }

    getBrightness = (attachments) => {
        let brightness = {};

        attachments.forEach(attachment => {
            const platformKey = getPlatformKey(attachment);
            brightness[platformKey] = {}
            
            const energies = getEnergies(attachment);
            
            brightness[platformKey] = Util.assign({}, generateBrightness(energies))
        });

        return brightness;
    }

    _validateEntry = (name, value) => {
        // Guard against undefined value (field doesn't exist in DB)
        // https://stackoverflow.com/a/7452352
        const isManual = this.state.isManuallyGenerated;
        const isPipelineIssue = this.state.isPipelineIssue;
        const isBolide = this.state.isBolide === "Bolide" ? true : false;
        if (value === void (0)) { value = "" }
        let retValue = false
        let tmpValue
        switch (name) {
            case 'eventDate':
                retValue = Util.isValidDateValue(value)
                break
            case 'eventTime':
                retValue = Util.isValidTimeValue(value)
                break
            case 'eventRejectReason':
                tmpValue = value.trim()
                retValue = tmpValue.length > 0
                break
            case 'duration':
                tmpValue = parseFloat(value)
                retValue = (tmpValue >= 0)
                break
            case 'latitude':
                if (isManual) {
                    tmpValue = parseFloat(value)
                    retValue = (tmpValue >= -90.0 && tmpValue <= 90.0)
                    break
                } else {
                    retValue = true
                    break
                }
            case 'longitude':
                if (isManual) {
                    tmpValue = parseFloat(value)
                    retValue = (tmpValue >= -180.0 && tmpValue <= 180.0)
                    break
                } else {
                    retValue = true
                    break
                }
                
            case 'latitudeDelta':
                if (isManual) {
                    tmpValue = parseInt(value)
                    retValue = (tmpValue >= 1 && tmpValue <= 45)
                    break
                } else {
                    retValue = true
                    break
                }
                
            case 'longitudeDelta':
                if (isManual) {
                    tmpValue = parseInt(value)
                    retValue = (tmpValue >= 1 && tmpValue <= 90)
                    break
                } else {
                    retValue = true
                    break
                }
                
            case 'beginningAltitude':
                tmpValue = parseFloat(value)
                if (!tmpValue) {
                    retValue = true
                } else {
                    retValue = this.state.isStereo ? tmpValue >= 0 : true
                }
                break
            case 'detectedBy':
                tmpValue = value.trim()
                retValue = tmpValue.length > 0
                break
            case 'confidenceRating':
                tmpValue = value.trim()
                retValue = (isPipelineIssue === true || isBolide === false) ? true : tmpValue.length > 0
                break
            case 'lightcurveStructure':
                tmpValue = value.trim()
                retValue = (isPipelineIssue === true || isBolide === false) ? true : tmpValue.length > 0
                break
            case 'nearbyLightningActivity':
                tmpValue = value.trim()
                retValue = (isPipelineIssue === true || isBolide === false) ? true : tmpValue.length > 0
                break
            case 'humanClassification':
                tmpValue = value.trim()
                retValue = (isPipelineIssue === true || isBolide === false) ? true : tmpValue.length > 0
                break

            default:
                retValue = true
        }
        return retValue
    }

    saveEventData = (cb) => {
        const { brightness,
            eventDate,
            eventTime,
            eventName,
            eventDescription,
            detectedBy,
            groundTrack,
            howFound,
            otherDetectingSources,
            confidenceRating,
            otherInformation,
            duration,
            latitude,
            lightcurveStructure,
            nearbyLightningActivity,
            longitude,
            latitudeDelta,
            longitudeDelta,
            beginningAltitude,
            eventAttachments,
            pdfFilenames,
            isManuallyGenerated,
            selectedEvent,
            humanClassification,
            isPipelineIssue } = this.state

        const dt = `${eventDate} ${eventTime}`
        const datetime = moment.utc(dt, 'MM/DD/YYYY HH:mm:ss.SSS').valueOf()

        // clear geoData before sending ... might reach post request size limitations...
        eventAttachments.forEach((a) => {
            //console.log("DEBUG::eventAttachments: ", a);
            

            const id = a._id ? a._id : a.tempId
            a.geoData = []
            a.mapMetaData = AppState.eventAttachmentMetaDataMap.get(id)
        })

        // Clear out "howFound" values for uninvolved sats.
        // Handles the edge case of editing an event and unchecking
        // a satellite from the "detected by" field

        // Update: need this only for manually entered events
        // Pipeline events will not have this case and we want to display howFound values for satellites that aren't in the
        // detectedByMap -> see NEA-493
        if (isManuallyGenerated) {
            for (const satName in this._detectedByMap) {
                if (!this._detectedByMap[satName]) {
                    delete howFound[satName];
                }
            }
        }

        const payload = {
            isNew: AppState.mode === C_MODE.C_ADD_MODE,
            id: selectedEvent ? selectedEvent._id : null,
            brightness: brightness,
            name: eventName,
            detectedBy: detectedBy,
            groundTrack: groundTrack,
            howFound: howFound,
            otherDetectingSources: otherDetectingSources,
            confidenceRating: confidenceRating,
            lightcurveStructure: lightcurveStructure,
            nearbyLightningActivity: nearbyLightningActivity,
            otherInformation: otherInformation,
            description: eventDescription,
            datetime: datetime,
            duration: parseFloat(duration),
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
            latitudeDelta: parseFloat(latitudeDelta),
            longitudeDelta: parseFloat(longitudeDelta),
            beginningAltitude: parseFloat(beginningAltitude),
            humanClassification: humanClassification,
            eventAttachments: eventAttachments,
            pdfFilenames: pdfFilenames,
            isManuallyGenerated: AppState.mode === C_MODE.C_ADD_MODE ? true : isManuallyGenerated,
            isPipelineIssue: isPipelineIssue,
            status: C_EVENT_STATUS.C_INITIAL
        }

        const getEventsMapping = {
            [C_MODULE_NAME.C_DATA_ENTRY]: AppState.getSmeEvents,
            [C_MODULE_NAME.C_DATA_VERIFICATION]: AppState.getReviewerEvents
        }
        const getEventsFunc = getEventsMapping[AppState.currentModuleName]

        AppState.saveEvent(payload, (res) => {
            if (res.success && (res.data?.success)) {
                const event = res.data
                // get all events related to module (ie. Data Entry, Data Verification) ...
                getEventsFunc((res) => {
                    if (res.success) {
                        // res = Object.assign({}, res, { event: event })
                        res = Util.assign({}, res, { event: event })
                        cb && cb(res)
                    } else {
                        // error
                        cb && cb(res)
                    }
                })
            } else {
                // error
                cb && cb(res)
            }
        })
    }

    getEventGeoData = (cb) => {
        const { eventDate,
            eventTime,
            duration,
            latitude,
            longitude,
            latitudeDelta,
            longitudeDelta,
            eventAttachments } = this.state

        const dt = `${eventDate} ${eventTime}`
        const datetime = moment.utc(dt, 'MM/DD/YYYY HH:mm:ss.SSS').valueOf()

        // clear geoData before sending ... might reach post request size limitations...
        eventAttachments.forEach((a) => {
            const id = a._id ? a._id : a.tempId
            a.geoData = []
            a.mapMetaData = AppState.eventAttachmentMetaDataMap.get(id)
        })

        const payload = {
            datetime: datetime,
            duration: parseInt(duration),
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
            latitudeDelta: parseFloat(latitudeDelta),
            longitudeDelta: parseFloat(longitudeDelta),
            eventAttachments: eventAttachments
        }

        AppState.getEventGeoData(payload, (res) => {
            cb && cb(res)
        })
    }

    handleKeyUp = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }
    
    handleChangeTextField = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleChange = name => event => {
        const valid = this._validateEntry(name, event.target.value)

        if (valid && (name === 'eventDate' || name === 'eventTime')) {
            const { eventDate, eventTime } = this.state
            let dateTime = null
            if (name === 'eventDate') {
                // check if time is valid
                if (Util.isValidTimeValue(eventTime)) {
                    dateTime = `${event.target.value} ${eventTime}`
                }
            } else if (name === 'eventTime') {
                // check if date is valid
                if (Util.isValidDateValue(eventDate)) {
                    dateTime = `${eventDate} ${event.target.value}`
                }
            }
            this.setState({
                eventDateError: false,
                eventTimeError: false
            })
        } else {
            this.setState({
                [name + 'Error']: !valid
            })
        }
    }

    handleBrightnessChange = satName => event => {
        let brightness = this.state.brightness;
        // Initialize the key/value if it does not exist
        !(brightness.hasOwnProperty(satName)) && (brightness[satName] = {});
        brightness[satName]["category"] = event.target.value;
        this.setState(brightness);
    }

    handleGroundTrackChange = satName => event => {
        let groundTrack = this.state.groundTrack;
        // Initialize the key/value if it does not exist
        !(groundTrack.hasOwnProperty(satName)) && (groundTrack[satName] = {});
        groundTrack[satName]["category"] = event.target.value;
        this.setState(groundTrack);
    }

    handleHowFoundChange = satName => event => {
        let howFound = this.state.howFound;
        howFound[satName] = event.target.value;

        // Since we will have previous data that will not match
        // 1:1 to the selections provided in the pipelines list,
        // we need to account for those.
        //
        // The current behavior is that if there are previous values
        // present, they will be overwritten once the user selects
        // any new values.
        if (howFound[satName].length > 0) {
            let prevData = true;
            for (const pipeline of this._howFoundPipelines) {
                if (howFound[satName][0] === pipeline.toLowerCase()) {
                    prevData = false;
                }
            }

            // Remove previous data before setting new values
            if (prevData) { howFound[satName].shift(); }
        }
        this.setState(howFound);
    }

    handleDetectedByCheckChange = name => event => {
        this._detectedByMap[name] = event.target.checked
        const detectedBy = Object.keys(this._detectedByMap).filter(satName => {
            return this._detectedByMap[satName]
        }).map(satName => {
            return satName
        }).join(',')

        const detectedByError = !detectedBy
        const isStereo = this._isStereo(detectedBy)
        this.setState({ detectedBy, detectedByError, isStereo })
    }

    handleIsBolideChange = event => {
        this.setState({ isBolide: event.target.value });
        if (event.target.value === "Bolide") {
            
            // Set the 'Bolide' option to be selectable and everything else to be disabled, set humanClassification value to 'Bolide'
            _humanClassificationCategories.forEach((cat, idx) => {
                if (idx === 0) {
                    cat.view = true;
                } else {
                    cat.view = false;
                }
            })
            this.setState({ humanClassification: "Bolide", humanClassificationError: false, bolideRadialChoice: true });
        }
        else if (event.target.value === "Non-Bolide") {
            
            // Set the 'Bolide' option to be disabled and everything else to be selectable, set humanClassificationError to true to indicate that
            // an option still needs to be selected
            _humanClassificationCategories.forEach((cat, idx) => {
                if (idx === 0) {
                    cat.view = false;
                } else {
                    cat.view = true;
                }
            }) 
            this.setState({ humanClassification: "", humanClassificationError: true, bolideRadialChoice: false });
        }
        this.forceUpdate()
    }

    handleIsPipelineChange = event => {
        // these are the human assessment fields that will be ignored if PipelineIssue is checked
        const fieldsToValidateRemove = [
            'confidenceRating',
            'lightcurveStructure',
            'nearbyLightningActivity',
            'humanClassification'
        ]
        // hypothesis is a Pipeline Issue
        if (event.target.checked) {
            this.setState({ isPipelineIssue: event.target.checked });
            fieldsToValidate = fieldsToValidate.filter((field) => !fieldsToValidateRemove.includes(field));
            fieldsToValidateRemove.forEach((field) => {
                const stateObject = {};
                stateObject[`${field}Error`] = false;
                this.setState(stateObject);
            })
            this.areEntriesValid();
        }
        //hypothesis is not a Pipeline Issue
        else {
            this.setState({ isPipelineIssue: event.target.checked });
            fieldsToValidate.push(...fieldsToValidateRemove);
            this.areEntriesValid()
        }
        
    }

    handleHumanClassificationChange = event => {
        this.setState({humanClassification: event.target.value, humanClassificationError: false});
    }

    handleConfidenceRatingRadioChange = event => {
        this.setState({ confidenceRating: event.target.value, confidenceRatingError: false })
    }

    handleLightcurveStructureChange = event => {
        this.setState({ lightcurveStructure: event.target.value, lightcurveStructureError: false })
    }

    handleNearbyLightningActivityChange = event => {
        this.setState({ nearbyLightningActivity: event.target.value, nearbyLightningActivityError: false })
    }

    handleEventCategoryChange = (event) => {
        this.setState({ humanClassification: event.target.checked ? event.target.checked : '' })
    }

    handleAddTag = (event) => {
        const otherInfo = this.state.otherInformation.indexOf(event.target.value) > -1 ? this.state.otherInformation.replace(event.target.value + ";", "") : [this.state.otherInformation, event.target.value + ";"].join("")
        this.setState({otherInformation: otherInfo});
    }

    onFileuploadSuccess = (data) => {
        let { eventAttachments } = this.state;
        eventAttachments.push(data);
        const detectedBy = this._generateDetectedBy(eventAttachments);
        const detectedByError = false;
        this._parseDetectedBy(detectedBy);
        const isStereo = this._isStereo(detectedBy);

        const brightness = this.getBrightness(eventAttachments);
        const brightnessError = Object.keys(brightness).length === 0;

        const groundTrack = generateGroundTrack(eventAttachments);
        const groundTrackError = Object.keys(groundTrack).length === 0;

        this.setState({
            brightness: brightness,
            brightnessError: brightnessError,
            eventAttachments: eventAttachments,
            eventAttachmentsError: false,
            detectedBy: detectedBy,
            detectedByError: detectedByError,
            groundTrack: groundTrack,
            groundTrackError: groundTrackError,
            isStereo: isStereo
        });
    }

    onFileuploadError = (error) => {
        this.setState({
            jsonData: JSON.stringify(error, null, 3)
        }, () => this.showMessage(error, 'error', true));
    }

    onUpdateAttachment = (data) => {
        const { index, range } = data
        let { eventAttachments } = this.state
        eventAttachments[index].range = range
        this.setState({
            eventAttachments: eventAttachments
        })
    }

    onRemoveAttachment = (index) => {
        let { eventAttachments } = this.state
        eventAttachments.splice(index, 1)

        const eventAttachmentsError = eventAttachments.length === 0
        const detectedBy = this._generateDetectedBy(eventAttachments)
        const detectedByError = detectedBy ? false : true
        this._parseDetectedBy(detectedBy)

        this.setState({
            eventAttachments: eventAttachments,
            eventAttachmentsError: eventAttachmentsError,
            detectedBy: detectedBy,
            detectedByError: detectedByError,
        })
    }

    onEventDataLoaded = (event) => {
        AppState.previousEventId = ''
        AppState.currentEventId = event._id
        AppState.setViewMode()
        this.setState({
            ...this._generateInitialState()
        })
    }

    onSetAddMode = () => {
        this.setState({
            ...this._generateInitialState(true),
        }, () => {
            this.areEntriesValid()
        })
    }

    onClickButtonEdit = (event) => {
        this.myRef.current.scrollIntoView();

        AppState.setEditMode(false, () => {
            this.forceUpdate()
        })
    }

    onCloseConfirmation = (cmd) => {
        if (cmd === 'ok') {
            this.deleteEvent()
        }
    }

    deleteEvent = () => {
        const { selectedEvent } = this.state
        if (selectedEvent) {
            let selectedEventIndex = AppState.selectedEventIndex
            AppState.deleteEvent(selectedEvent._id, (res) => {
                if (!res.success) {
                    this.showMessage(res.error, 'error', true)
                    return
                }
                this.showMessage('Event has been successfully deleted')
                Util.debounce(() => {
                    if (AppState.eventList.length === 0) {
                        AppState.currentEventId = ''
                        AppState.previousEventId = ''
                        AppState.setAddMode()
                        this.setState({
                            ...this._generateInitialState(true)
                        })
                    } else {
                        if (selectedEventIndex >= AppState.eventList.length) {
                            selectedEventIndex = AppState.eventList.length - 1
                        }
                        const event = AppState.eventList[selectedEventIndex]
                        const eventId = event._id
                        AppState.currentEventId = eventId
                        AppState.getEvent(eventId, () => {
                            // this.showMessage('Event has been successfully deleted')
                        })
                    }
                }, 500)()
            })
        }
    }

    onClickButtonDelete = (event) => {
        const { showConfirmation } = this.props
        const dialogOptions = {
            titleNode: <Typography style={{ color: 'white' }}>Delete</Typography>,
            contentNode: <div>Are you sure?</div>,
            okLabel: 'Yes',
            cancelLabel: 'No',
            onClose: this.onCloseConfirmation.bind(this)
        }
        showConfirmation(dialogOptions)
    }

    onClickButtonSubmit = (event, button) => {
        const { selectedEvent } = this.state
        
        this.areEntriesValid((valid) => {
            if (valid) {
                AppState.submitEvent({ id: selectedEvent._id }, (res) => {
                    if (!res.success) {
                        this.showMessage(res.error, 'error', true)
                        button.reset();
                        return
                    }
                    this.showMessage('Event has been successfully submitted')
                    AppState.getEvent(selectedEvent._id, () => {
                        Util.debounce(() => { this.showMessage('') }, 3000)()
                    })
                })
            } else {
                this.showMessage("Error: Missing required data, Please review event before submitting", 'warning', true);
                button.reset();
                return
            }
        })
    }

    rejectEvent = () => {
        const { selectedEvent, eventRejectReason } = this.state
        AppState.rejectEvent({ id: selectedEvent._id, reason: eventRejectReason }, (res) => {
            if (!res.success) {
                this.showMessage(res.error, 'error', true)
                return
            }
            this.showMessage('Event has been successfully rejected')
            AppState.getEvent(selectedEvent._id, () => {
                Util.debounce(() => { this.showMessage('') }, 3000)()
            })
        })
    }

    onClickButtonRejectOk = () => {
        const { eventRejectReason } = this.state
        if (eventRejectReason.length > 0) {
            this.props.hideConfirmation()
            this.rejectEvent()
        }
    }

    onClickButtonRejectCancel = () => {
        this.props.hideConfirmation()
    }

    onClickButtonReject = (event, button) => {
        const { classes, showConfirmation } = this.props
        const dialogOptions = {
            titleNode: <Typography style={{ color: 'white' }}>Reject</Typography>,
            contentNode: <div>
                <TextField
                    label="Reason for Rejection"
                    className={classes.textField}
                    style={{ width: '100%', paddingRight: 10 }}
                    multiline
                    rows="3"
                    onKeyUp={this.handleKeyUp('eventRejectReason')}
                    onChange={this.handleChange('eventRejectReason')}
                    inputProps={{ maxLength: 200 }}
                    variant="outlined"
                    InputLabelProps={{
                        classes: {
                            outlined: classes.inputLabel
                        }
                    }}
                />
            </div>,
            dialogCss: classes.rejectConfirmation,
            actionNode: (
                [
                    <Button style={{ width: 100 }} key={2} variant='contained' onClick={this.onClickButtonRejectOk} color="primary">
                        OK
                    </Button>,
                    <Button style={{ width: 100 }} key={1} variant='outlined' onClick={this.onClickButtonRejectCancel} color="primary">
                        Cancel
                    </Button>
                ]
            )
        }
        showConfirmation(dialogOptions)
    }

    onClickButtonPublish = (event, button) => {
        const { selectedEvent } = this.state;
        // clear geoData before sending ... might reach post request size limitations...
        let metaDataMaps = [];
        selectedEvent.attachments.forEach((a) => {
            //console.log("DEBUG::eventAttachments: ", a);
            

            const id = a._id ? a._id : a.tempId
            a.geoData = []
            metaDataMaps.push(AppState.eventAttachmentMetaDataMap.get(id))
        })
        AppState.publishEvent({ id: selectedEvent._id, metaDataMaps: metaDataMaps }, (res) => {
            if (!res.success) {
                this.showMessage(res.error, 'error', true)
                button.reset()
                return
            }
            this.showMessage('Event has been successfully published')
            AppState.getEvent(selectedEvent._id, () => {
                Util.debounce(() => { this.showMessage('') }, 3000)()
            })
        })
    }

    onClickButtonCancel = (event) => {
        AppState.setViewMode(true)
        this.setState({
            ...this._generateInitialState()
        })
    }

    onClickButtonSave = (event, button) => {
        const { eventDate, eventTime } = this.state
        const dateTime = `${eventDate} ${eventTime}`

        const save = () => {
            this.saveEventData((res) => {
                if (!res.success) {
                    button.reset()
                    this.showMessage(res.error, 'error', true)
                    return
                }
                if (res.data.hasOwnProperty("success") && !res.data?.success) {
                    button.reset()
                    this.showMessage(res.data.error, 'error', true)
                    return
                }
                if (res.data.length > 0) {
                    this.showMessage('Event has been successfully saved')
                    const eventId = res.event.data._id
                    AppState.getEvent(eventId, (res) => {
                        if (res.success) {
                            AppState.currentEventId = eventId
                            Util.debounce(() => { this.showMessage('') }, 3000)()
                        } else {
                            console.log('DEBUG:error', res.error)
                            this.showMessage(res.error, 'error', true)
                        }
                    })
                }
            })
        }

        this.areEntriesValid((valid) => {
            if (valid) {
                save()
            } else {
                button.reset()
            }
        })
    }

    onClickButtonRefresh = (event) => {
        this.areEntriesValid((valid) => {
            if (valid) {
                this.getEventGeoData((res) => {
                    if (res.success) {
                        this.setState({
                            eventRefreshAttachments: res.data
                        })
                    } else {
                        this.showMessage(res.error, 'error', true)
                    }
                })
            }
        })
    }

    _isStereo = () => {
        const detectedBy = this._getDetectedBy();
        if (detectedBy.includes("GLM-16") && Object.keys(this._detectedByMap)
            .filter(satName => this._detectedByMap[satName]).length >= 2) {
            return true
        } else {
            return false
        }  
    }

    _generateDetectedBy = (attachments) => {
        let platform = {}
        attachments.forEach((a) => {
            const platformKey = (a.platformId === 'G16') ? 'GLM-16' : (a.platformId === 'G17') ? 'GLM-17' : (a.platformId === 'G18') ? 'GLM-18' : '';
            platform[platformKey] = platformKey;
        });
        return Object.keys(platform).join(',');
    }

    /**
     * @returns A list of platformKeys representing the satellites this event was detected by
     */
    _getDetectedBy = () => {
        return Object.keys(this._detectedByMap).filter(satName => this._detectedByMap[satName]);
    }

    _parseDetectedBy = (data) => {
        const tmp = data ? data.split(',').map(d => d.trim()) : []
        Object.keys(this._detectedByMap).forEach(m => {
            this._detectedByMap[m] = (tmp.indexOf(m) !== -1)
        })
    }

    _generateInitialState = (addMode = false) => {
        const selectedEvent = addMode ? null : AppState.selectedEvent
        let dt = moment.utc() // (new Date('11/01/2018 06:36:40 PM'))
        if (selectedEvent) {
            dt = moment.utc(selectedEvent.datetime)
        }
        this._dateTime = selectedEvent ? dt.format('MM/DD/YYYY HH:mm:ss') : null
        const confidencRatingValues = ["low", "medium", "high"]

        const state = {
            _id: selectedEvent ? selectedEvent._id : "",
            brightness: selectedEvent ? selectedEvent.brightness : {},
            eventDate: selectedEvent ? dt.format('MM/DD/YYYY') : '',
            eventTime: selectedEvent ? dt.format('HH:mm:ss.SSS') : '',
            eventName: selectedEvent ? selectedEvent.name : '',
            eventDescription: selectedEvent ? selectedEvent.description : '',
            detectedBy: selectedEvent ? selectedEvent.detectedBy : '',
            groundTrack: selectedEvent ? selectedEvent.groundTrack : {},
            howFound: selectedEvent ? selectedEvent.howFound : {},
            otherDetectingSources: selectedEvent ? selectedEvent.otherDetectingSources : '',
            confidenceRating: selectedEvent ? (confidencRatingValues.includes(selectedEvent.confidenceRating) ? selectedEvent.confidenceRating : '' ) : '',
            otherInformation: selectedEvent ? selectedEvent.otherInformation : '',
            lightcurveStructure: selectedEvent ? selectedEvent.lightcurveStructure : '',
            nearbyLightningActivity: selectedEvent ? selectedEvent.nearbyLightningActivity : '',
            duration: selectedEvent ? (selectedEvent.duration ? selectedEvent.duration : 20) : 20,
            latitude: selectedEvent ? selectedEvent.latitude : '',
            longitude: selectedEvent ? selectedEvent.longitude : '',
            latitudeDelta: selectedEvent ? selectedEvent.latitudeDelta : 1,
            longitudeDelta: selectedEvent ? selectedEvent.longitudeDelta : 1,
            beginningAltitude: selectedEvent ? selectedEvent.beginningAltitude : '',
            eventRejectReason: '',
            isStereo: selectedEvent ? this._isStereo(selectedEvent.detectedBy) : false,
            isManuallyGenerated : selectedEvent ? selectedEvent.isManuallyGenerated : true,
            humanClassification: selectedEvent ? selectedEvent.humanClassification : '',
            isPipelineIssue: selectedEvent ? selectedEvent?.isPipelineIssue !== 'undefined' ? selectedEvent.isPipelineIssue : false : false,
            worldMapAccordionBC: '#ECE6DE',
            pdfAccordionBC: 'white',
            accordionExpanded: false,
            validationReportExpanded: true,

            /* Initial Error State */
            brightnessError: false,
            confidenceRatingError: false,
            detectedByError: false,
            eventAttachmentsError: false,
            eventDateError: false,
            eventTimeError: false,
            groundTrackError: false,
            latitudeError: false,
            latitudeDeltaError: false,
            longitudeError: false,
            longitudeDeltaError: false,
            lightcurveStructureError: false,
            nearbyLightningActivityError: false,
            beginningAltitudeError: false,
            humanClassificationError: false,

            eventAttachments: selectedEvent ? selectedEvent.attachments : [],
            eventList: AppState.eventList,
            pdfFilenames: selectedEvent ? selectedEvent.pdfFilenames : [],
            selectedEvent: AppState.selectedEvent,
            isBolide: selectedEvent ? (selectedEvent.humanClassification === 'Bolide' ? 'Bolide' : _humanClassificationCategories.some(h => h.name === selectedEvent.humanClassification) ? 'Non-Bolide' : "") : "",
            bolideRadialChoice: selectedEvent ? (selectedEvent.humanClassification === 'Bolide' || selectedEvent.humanClassification === "" ? true : false) : true,
        }

        this._parseDetectedBy(state.detectedBy)

        return state
    }

    constructor(props) {
        super(props)

        if (AppState.eventList.length === 0) {
            AppState.setAddMode()
        } else {
            AppState.setViewMode()
        }

        this._detectedByMap = {
            'GLM-16': false,
            'GLM-17': false,
            'GLM-18': false
        }

        this._howFoundPipelines = [
            "Forest",
            "Stereo",
            "Tip off",
            "Convnet",
            "Stereo Match", 
            "classifier"
        ]

        this._dateTime = null
        this.myRef = React.createRef();
        this.state = this._generateInitialState()
    }

    componentDidMount() {
        super.componentDidMount()
        const obj = this.validateEntries()
        this.setState(obj, () => {
            AppState.addEventListener(C_EVENT_IDS.C_ON_EVENT_DATA_LOADED, this.onEventDataLoaded)
            AppState.addEventListener(C_EVENT_IDS.C_ON_UPDATE_ATTACHMENT, this.onUpdateAttachment)
            AppState.addEventListener(C_EVENT_IDS.C_ON_REMOVE_ATTACHMENT, this.onRemoveAttachment)
            AppState.addEventListener(C_EVENT_IDS.C_ON_SET_ADD_MODE, this.onSetAddMode)
        })
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        AppState.removeEventListener(C_EVENT_IDS.C_ON_EVENT_DATA_LOADED, this.onEventDataLoaded)
        AppState.removeEventListener(C_EVENT_IDS.C_ON_UPDATE_ATTACHMENT, this.onUpdateAttachment)
        AppState.removeEventListener(C_EVENT_IDS.C_ON_REMOVE_ATTACHMENT, this.onRemoveAttachment)
        AppState.removeEventListener(C_EVENT_IDS.C_ON_SET_ADD_MODE, this.onSetAddMode)
    }

    render() {
        const { classes } = this.props
        let {
            _id,
            brightness,
            eventDate,
            eventTime,
            duration,
            groundTrack,
            latitude,
            longitude,
            latitudeDelta,
            longitudeDelta,
            eventName,
            detectedBy,
            howFound,
            otherDetectingSources,
            confidenceRating,
            otherInformation,
            eventDescription,
            eventAttachments,
            eventRefreshAttachments,
            eventDateError,
            eventTimeError,
            durationError,
            latitudeError,
            longitudeError,
            latitudeDeltaError,
            longitudeDeltaError,
            humanClassificationError,
            beginningAltitude,
            lightcurveStructure,
            nearbyLightningActivity,
            eventAttachmentsError,
            selectedEvent,
            isManuallyGenerated,
            pdfFilenames,
            humanClassification,
            isPipelineIssue,
            isBolide,
            bolideRadialChoice
        } = this.state

        const event = {
            _id,
            brightness,
            eventDate,
            eventTime,
            duration,
            groundTrack,
            latitude,
            longitude,
            latitudeDelta,
            longitudeDelta,
            beginningAltitude,
            eventName,
            detectedBy,
            confidenceRating,
            lightcurveStructure,
            nearbyLightningActivity,
            howFound,
            otherDetectingSources,
            otherInformation,
            eventDescription,
            isManuallyGenerated,
            pdfFilenames,
            humanClassification,
            isPipelineIssue,
            isBolide,
            bolideRadialChoice
        };

        const tags = AppState._adminTags;
        let eventStatus = selectedEvent ? selectedEvent.status : ''
        let eventRejectedReason = selectedEvent ? selectedEvent.reason : ''
        const eventStatusIcon = eventStatus ? C_STATUS_MAP_ICON[eventStatus].icon : null
        const eventTypeIcon = isManuallyGenerated ? <CreateIcon/> : <DnsIcon/>
        const eventStatusStyleName = eventStatus.toLowerCase() + 'Status'
        const fileUploadButtonDisabled = (eventDateError ||
            eventTimeError ||
            durationError ||
            latitudeError ||
            longitudeError ||
            latitudeDeltaError ||
            longitudeDeltaError)

        const appBarMap = {
            [C_MODE.C_ADD_MODE]: {
                appBarTitle: 'Create New Hypothesis',
                actionNodes: (
                    <div style={{ marginRight: -15 }}>
                        <ButtonProgress className={classes.button} variant="contained" style={{ width: 90 }} color="primary" onClick={this.onClickButtonSave}>Save</ButtonProgress>
                        {AppState.eventList.length > 0 && <Button className={classes.button} variant="outlined" color="primary" onClick={this.onClickButtonCancel}>Cancel</Button>}
                    </div>
                )
            },
            [C_MODE.C_EDIT_MODE]: {
                appBarTitle: eventName,
                actionNodes: (
                    <div style={{ marginRight: -15 }}>
                        <ButtonProgress className={classes.button} variant="contained" style={{ width: 90 }} color="primary" onClick={this.onClickButtonSave}>Update</ButtonProgress>
                        {AppState.eventList.length > 0 && <Button className={classes.button} variant="outlined" color="primary" onClick={this.onClickButtonCancel}>Cancel</Button>}
                    </div>
                )
            },
            [C_MODE.C_VIEW_MODE]: {
                appBarTitle: eventName,
                actionNodes: (
                    AppState.currentModuleName === C_MODULE_NAME.C_DATA_ENTRY ?
                        <div style={{ marginRight: -15 }}>
                            <Button className={classes.button} variant="contained" color="primary" onClick={this.onClickButtonEdit}>Edit</Button>
                            {/* <Button className={classes.button} variant="contained" style={{ width: 90 }} color="primary" onClick={this.onClickButtonDelete}>Delete</Button> */}
                            <ButtonProgress className={classes.button} variant="contained" style={{ width: 90 }} color="primary" onClick={this.onClickButtonSubmit}>Submit</ButtonProgress>
                        </div>
                        : AppState.currentModuleName === C_MODULE_NAME.C_DATA_VERIFICATION ?
                            <div style={{ marginRight: -15 }}>
                                <Button className={classes.button} variant="contained" style={{ width: 90 }} color="primary" onClick={this.onClickButtonReject}>Reject</Button>
                                <ButtonProgress className={classes.button} variant="contained" style={{ width: 90 }} color="primary" onClick={this.onClickButtonPublish}>Publish</ButtonProgress>
                            </div> :
                            <div></div>
                )
            }
        }

        const inputModeMap = {
            [C_MODE.C_ADD_MODE]: { variant: 'outlined', readOnly: false, disabled: false },
            [C_MODE.C_EDIT_MODE]: { variant: 'outlined', readOnly: false, disabled: false },
            [C_MODE.C_VIEW_MODE]: { variant: 'standard', readOnly: true, disabled: true },
        }

        const isViewMode = (AppState.mode === C_MODE.C_VIEW_MODE)
        let showActionButtons = false

        if (isViewMode) {
            eventName = eventName || '-'
            detectedBy = detectedBy || '-'
            howFound = howFound || '-'
            otherDetectingSources = otherDetectingSources || '-'
            confidenceRating = confidenceRating || '-'
            lightcurveStructure = lightcurveStructure || '-'
            nearbyLightningActivity = nearbyLightningActivity || '-'
            beginningAltitude = beginningAltitude || '-'
        }
        if (AppState.currentModuleName === C_MODULE_NAME.C_DATA_ENTRY) {
            if (AppState.mode === C_MODE.C_ADD_MODE) {
                eventStatus = ''
                showActionButtons = true
            } else {
                showActionButtons = (selectedEvent &&
                    [C_EVENT_STATUS.C_INITIAL, C_EVENT_STATUS.C_REJECTED].indexOf(selectedEvent.status) !== -1)
            }
        } else if (AppState.currentModuleName === C_MODULE_NAME.C_DATA_VERIFICATION) {
            showActionButtons = (selectedEvent &&
                [C_EVENT_STATUS.C_SUBMITTED].indexOf(selectedEvent.status) !== -1)
        }

        return (
            <div className={classes.root}>
                <div className={classes.toolbar} ref={this.myRef} />
                <AppBar position="fixed" color="default" className={classes.appBar} enableColorOnDark>
                    <Toolbar className={classes.appBarToolbar}>
                        {eventStatus &&
                            <Chip
                                variant="outlined"
                                icon={eventStatusIcon}
                                label={eventStatus}
                                style={{ marginLeft: -10 }}
                                className={classes.chip}
                                classes={{ icon: classes[eventStatusStyleName], label: classes[eventStatusStyleName] }}
                            />
                        }
                        <Chip
                            variant='outlined'
                            icon={eventTypeIcon}
                            label={isManuallyGenerated ? "Manual" : "Pipeline"}
                            // style={{ marginLeft: -10 }}
                            className={classes.chip}
                        />
                        {AppState.mode !== C_MODE.C_ADD_MODE &&
                            <div style={{ marginLeft: 20 }}>
                                <Typography variant='subtitle1' color='inherit'>
                                    {_id}
                                </Typography>
                            </div>
                        }
                        <Typography variant="subtitle1" color="inherit">
                            {appBarMap[AppState.mode].appBarTitle}
                        </Typography>
                        <div className={classes.grow} />
                        {showActionButtons && appBarMap[AppState.mode].actionNodes}
                    </Toolbar>
                </AppBar>
                <div className={classes.toolbar}/>
                <div className={classes.container}>

                    {eventStatus === C_EVENT_STATUS.C_REJECTED && eventRejectedReason &&
                        <div className={classNames(classes.flexItemRow, classes.reasonPanel)}>
                            <FeedbackIcon color="error" style={{ marginRight: 10 }}></FeedbackIcon><Typography className={classes.rejectedStatus}>{eventRejectedReason}</Typography>
                        </div>
                    }

                    {isViewMode && <EventFormView event={event} classes={classes} />}

                    {!isViewMode && <div>
                        <div className={classes.flexItemRow} style={{ borderBottom: 'none' }}>
                            <TextField
                                style={{ flex: 'none', width: 130 }}
                                className={classes.textField}
                                error={eventDateError}
                                label="Date"
                                aria-label="data-input"
                                value={eventDate}
                                onBlur={this.handleKeyUp('eventDate')}
                                onKeyUp={this.handleKeyUp('eventDate')}
                                onChange={this.handleChange('eventDate')}
                                margin="dense"
                                variant={inputModeMap[AppState.mode].variant}
                                autoFocus={true}
                                disabled={!isManuallyGenerated}
                                InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                        outlined: classes.inputLabel
                                    }
                                }}
                                InputProps={{
                                    inputProps: { format: '##/##/####', placeholder: "MM/DD/YYYY", mask: ['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y'] },
                                    inputComponent: TextFieldPatternFormat,
                                    readOnly: inputModeMap[AppState.mode].readOnly
                                }}
                            />

                            <TextField
                                style={{ flex: 'none', width: 134 }}
                                className={classes.textField}
                                error={eventTimeError}
                                label="Time"
                                value={eventTime}
                                onBlur={this.handleKeyUp('eventTime')}
                                onKeyUp={this.handleKeyUp('eventTime')}
                                onChange={this.handleChange('eventTime')}
                                margin="dense"
                                variant={inputModeMap[AppState.mode].variant}
                                disabled={!isManuallyGenerated}
                                InputLabelProps={{
                                    shrink: true,
                                    classes: {
                                        outlined: classes.inputLabel
                                    }
                                }}
                                InputProps={{
                                    inputProps: { format: '##:##:##.###', placeholder: "hh:mm:ss.SSS", mask: ['h', 'h', 'm', 'm', 's', 's', 'S', 'S', 'S'] },
                                    inputComponent: TextFieldPatternFormat,
                                    readOnly: inputModeMap[AppState.mode].readOnly
                                }}
                            />

                            <div className={classes.flexItemRow} style={{ flex: 1, justifyContent: 'start', borderBottom: 'none' }}>
                                <TextField
                                    style={{ width: 90 }}
                                    label="Duration"
                                    // type="number"
                                    className={classes.textField}
                                    error={this.state.durationError}
                                    value={this.state.duration}
                                    onKeyUp={this.handleKeyUp('duration')}
                                    onChange={this.handleChange('duration')}
                                    margin="dense"
                                    variant={inputModeMap[AppState.mode].variant}
                                    disabled={!isManuallyGenerated}
                                    InputLabelProps={{
                                        classes: {
                                            outlined: classes.inputLabel
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: { maxLength: 6, decimalScale: 3 },
                                        inputComponent: TextFieldFormat,
                                        readOnly: inputModeMap[AppState.mode].readOnly
                                    }}
                                />
                                <TextField
                                    style={{ width: 100 }}
                                    label="Latitude"
                                    // type="number"
                                    className={classes.textField}
                                    error={this.state.latitudeError}
                                    value={this.state.latitude}
                                    onKeyUp={this.handleKeyUp('latitude')}
                                    onChange={this.handleChange('latitude')}
                                    margin="dense"
                                    variant={inputModeMap[AppState.mode].variant}
                                    disabled={!isManuallyGenerated}
                                    InputLabelProps={{
                                        classes: {
                                            outlined: classes.inputLabel
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: { maxLength: 6, decimalScale: 2 },
                                        inputComponent: TextFieldFormat,
                                        readOnly: inputModeMap[AppState.mode].readOnly
                                    }}
                                />
                                <TextField
                                    style={{ width: 110 }}
                                    label="Longitude"
                                    // type="number"
                                    className={classes.textField}
                                    error={this.state.longitudeError}
                                    value={this.state.longitude}
                                    onKeyUp={this.handleKeyUp('longitude')}
                                    onChange={this.handleChange('longitude')}
                                    margin="dense"
                                    variant={inputModeMap[AppState.mode].variant}
                                    disabled={!isManuallyGenerated}
                                    InputLabelProps={{
                                        classes: {
                                            outlined: classes.inputLabel
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: { maxLength: 6, decimalScale: 2 },
                                        inputComponent: TextFieldFormat,
                                        readOnly: inputModeMap[AppState.mode].readOnly
                                    }}
                                />
                                <TextField
                                    style={{ width: 100 }}
                                    label="Δ&nbsp;Latitude"
                                    // type="number"
                                    className={classes.textField}
                                    error={this.state.latitudeDeltaError}
                                    value={this.state.latitudeDelta}
                                    onKeyUp={this.handleKeyUp('latitudeDelta')}
                                    onChange={this.handleChange('latitudeDelta')}
                                    margin="dense"
                                    variant={inputModeMap[AppState.mode].variant}
                                    disabled={!isManuallyGenerated}
                                    InputLabelProps={{
                                        classes: {
                                            outlined: classes.inputLabel
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: { maxLength: 5, decimalScale: 2 },
                                        inputComponent: TextFieldFormat,
                                        readOnly: inputModeMap[AppState.mode].readOnly
                                    }}
                                />
                                <TextField
                                    style={{ width: 100 }}
                                    label="Δ&nbsp;Longitude"
                                    // type="number"
                                    className={classes.textField}
                                    error={this.state.longitudeDeltaError}
                                    value={this.state.longitudeDelta}
                                    onKeyUp={this.handleKeyUp('longitudeDelta')}
                                    onChange={this.handleChange('longitudeDelta')}
                                    margin="dense"
                                    variant={inputModeMap[AppState.mode].variant}
                                    disabled={!isManuallyGenerated}
                                    InputLabelProps={{
                                        classes: {
                                            outlined: classes.inputLabel
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: { maxLength: 5, decimalScale: 2 },
                                        inputComponent: TextFieldFormat,
                                        readOnly: inputModeMap[AppState.mode].readOnly
                                    }}
                                />
                                <TextField
                                    style={{ width: 190 }}
                                    label="Beginning Altitude (km)"
                                    // type="number"
                                    // disabled={this.state.isStereo === false} old logic: disabled if event is not stereo?
                                    disabled={!isManuallyGenerated}
                                    className={classes.textField}
                                    error={this.state.beginningAltitudeError}
                                    value={this.state.beginningAltitude}
                                    onKeyUp={this.handleKeyUp('beginningAltitude')}
                                    onChange={this.handleChange('beginningAltitude')}
                                    margin="dense"
                                    variant={inputModeMap[AppState.mode].variant}
                                    InputLabelProps={{
                                        classes: {
                                            outlined: classes.inputLabel
                                        }
                                    }}
                                    InputProps={{
                                        inputProps: { maxLength: 5, decimalScale: 2 },
                                        inputComponent: TextFieldFormat,
                                        readOnly: inputModeMap[AppState.mode].readOnly
                                    }}
                                />
                                {AppState.mode === C_MODE.C_EDIT_MODE && isManuallyGenerated &&
                                    <Fab size="small" color="default" aria-label="Refresh" className={classes.margin} style={{ flex: 'none' }}>
                                        <RefreshIcon fontSize="small" onClick={this.onClickButtonRefresh} />
                                    </Fab>
                                }
                            </div>
                        </div>
                        <div className={classes.flexItemRow} style={{ borderBottom: 'none', justifyContent: 'start' }}>
                            <GroupContainer label='Lightcurve Structure' error={this.state.lightcurveStructureError}>
                                <RadioGroup
                                    style={{ flexDirection: 'row' }}
                                    name="lightcurve-structure"
                                    className={classes.group}
                                    value={lightcurveStructure}
                                    onChange={this.handleLightcurveStructureChange}
                                >
                                    <FormControlLabel
                                        color="primary"
                                        value="marginal"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="Marginal"
                                        style={{ paddingLeft: 10 }}
                                        disabled={!bolideRadialChoice || isPipelineIssue }
                                    />
                                    <FormControlLabel
                                        value="minimal"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="Minimal"
                                        style={{ paddingLeft: 10 }}
                                        disabled={!bolideRadialChoice || isPipelineIssue }
                                    />
                                    <FormControlLabel
                                        value="good"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="Good"
                                        style={{ paddingLeft: 10 }}
                                        disabled={!bolideRadialChoice || isPipelineIssue }
                                    />
                                    <FormControlLabel
                                        value="very good"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="Very Good"
                                        style={{ paddingLeft: 10, marginRight: 0 }}
                                        disabled={!bolideRadialChoice || isPipelineIssue}
                                    />
                                </RadioGroup>
                            </GroupContainer>
                            <GroupContainer label='Nearby Lightning Activity' error={this.state.nearbyLightningActivityError}>
                                <RadioGroup
                                    style={{ flexDirection: 'row' }}
                                    name="nearby-lightning-activity"
                                    className={classes.group}
                                    value={nearbyLightningActivity}
                                    onChange={this.handleNearbyLightningActivityChange}
                                >
                                    <FormControlLabel
                                        color="primary"
                                        value="isolated"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="Isolated"
                                        style={{ paddingLeft: 10 }}
                                        disabled={!bolideRadialChoice || isPipelineIssue }
                                    />
                                    <FormControlLabel
                                        value="not isolated"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="Not Isolated"
                                        style={{ paddingLeft: 10 }}
                                        disabled={!bolideRadialChoice || isPipelineIssue }
                                    />
                                    <FormControlLabel
                                        value="not assessed"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="Not Assessed"
                                        style={{ paddingLeft: 10, marginRight: 0 }}
                                        disabled={!bolideRadialChoice || isPipelineIssue }
                                    />
                                </RadioGroup>
                            </GroupContainer>
                            <GroupContainer label='Detected By' error={this.state.detectedByError}>
                                <FormGroup style={{ flexDirection: 'row' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={!isManuallyGenerated}
                                                style={{ padding: '0px 5px' }}
                                                checked={this._detectedByMap['GLM-16']}
                                                onChange={this.handleDetectedByCheckChange('GLM-16')} value="GLM-16"
                                            />
                                        }
                                        label="GLM-16"
                                        style={{ paddingRight: 10, paddingLeft: 5 }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={!isManuallyGenerated}
                                                style={{ padding: '0px 5px' }}
                                                checked={this._detectedByMap['GLM-17']}
                                                onChange={this.handleDetectedByCheckChange('GLM-17')} value="GLM-17"
                                            />
                                        }
                                        label="GLM-17"
                                        style={{ marginRight: 0 }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={!isManuallyGenerated}
                                                style={{ padding: '0px 5px' }}
                                                checked={this._detectedByMap['GLM-18']}
                                                onChange={this.handleDetectedByCheckChange('GLM-18')} value="GLM-18"
                                            />
                                        }
                                        label="GLM-18"
                                        style={{ paddingLeft: 20 }}
                                    />
                                </FormGroup>
                            </GroupContainer>
                            <GroupContainer label='Confidence Rating' error={this.state.confidenceRatingError}>
                                <RadioGroup
                                    style={{ flexDirection: 'row' }}
                                    name="confidence-rating"
                                    className={classes.group}
                                    value={confidenceRating}
                                    onChange={this.handleConfidenceRatingRadioChange}
                                >
                                    <FormControlLabel
                                        color="primary"
                                        value="low"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="Low"
                                        style={{ paddingLeft: 10 }}
                                        disabled={!bolideRadialChoice || isPipelineIssue }
                                    />
                                    <FormControlLabel
                                        value="medium"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="Medium"
                                        style={{ paddingLeft: 10 }}
                                        disabled={!bolideRadialChoice || isPipelineIssue }
                                    />
                                    <FormControlLabel
                                        value="high"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="High"
                                        style={{ paddingLeft: 10, marginRight: 0 }}
                                        disabled={!bolideRadialChoice || isPipelineIssue }
                                    />
                                </RadioGroup>
                            </GroupContainer>
                            <GroupContainer label='Pipeline Issue'>
                                <FormControlLabel
                                    color="primary"
                                    control={
                                        <Checkbox 
                                            checked={isPipelineIssue} 
                                            onChange={this.handleIsPipelineChange} 
                                            style={{ padding: 0 }} 
                                            value={isPipelineIssue}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                    label="Pipeline Issue"
                                    style={{ paddingLeft: 10 }}
                                />
                            </GroupContainer>
                            <GroupContainer label='Bolide vs Non Bolide'>
                                <RadioGroup
                                    style={{ flexDirection: 'row' }}
                                    name="bolide-assessment"
                                    className={classes.group}
                                    value={isBolide}
                                    onChange={this.handleIsBolideChange}
                                >
                                    <FormControlLabel
                                        color="primary"
                                        value="Bolide"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="Bolide"
                                        style={{ paddingLeft: 10 }}
                                    />
                                    <FormControlLabel
                                        color="primary"
                                        value="Non-Bolide"
                                        control={<Radio style={{ padding: 0 }} />}
                                        label="Non-Bolide"
                                        style={{ paddingLeft: 10 }}
                                    />
                                </RadioGroup>
                            </GroupContainer>
                        </div>
                        <div className={classes.flexItemRow} style={{ borderBottom: 'none' }}>
                            {
                                howFound && Object.keys(howFound).map(satName => {
                                    return (
                                        <FormControl key={satName} variant="outlined" className={classes.formControl}>
                                            <InputLabel id="how-found-select-label" className={classes.inputLabel}>{`How Found: ${satName}`}</InputLabel>
                                            <Select
                                                variant="outlined"
                                                multiple
                                                style={{ flex: 1 }}
                                                className={classes.select}
                                                labelId="how-found-select-label"
                                                id="how-found-select"
                                                value={howFound[satName] || []}
                                                renderValue={(selection) => {
                                                    return selection.join(", ")
                                                }}
                                                onChange={this.handleHowFoundChange(satName)}
                                                label={`How Found: ${satName}`}
                                                labelplacement="top"
                                                readOnly={inputModeMap[AppState.mode].readOnly}>
                                                {this._howFoundPipelines.map((pipeline) => {
                                                    const pipelineLower = pipeline.toLowerCase();
                                                    return (
                                                        <MenuItem key={pipelineLower} value={pipelineLower}>
                                                            <Checkbox checked={howFound[satName] && howFound[satName].indexOf(pipelineLower) > -1} />
                                                            <ListItemText primary={pipeline} />
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    );
                                })
                            }
                            {
                                brightness && this._getDetectedBy().map(satName => {
                                    return (
                                        <FormControl key={satName} variant="outlined" className={classes.formControl}>
                                            <InputLabel id="brightness-select-label" className={classes.inputLabel}>{`Brightness: ${satName}`}</InputLabel>
                                            <Select
                                                variant="outlined"
                                                style={{ flex: 1 }}
                                                className={classes.select}
                                                labelId="brightness-select-label"
                                                id="brightness-select"
                                                value={brightness.hasOwnProperty(satName) && brightness[satName]["category"] || ""}
                                                label={`Brightness: ${satName}`}
                                                onChange={this.handleBrightnessChange(satName)}
                                                labelplacement="top"
                                                disabled={!isManuallyGenerated}
                                                classes={{
                                                    // select: classes.select
                                                }}>
                                                {Object.keys(BRIGHTNESS_CATEGORIES).map((level) => {
                                                    return (
                                                        <MenuItem key={level} value={level}>
                                                            <ListItemText primary={level} />
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    );
                                })
                            }
                            {
                                groundTrack && this._getDetectedBy().map(satName => {
                                    return (
                                        <FormControl key={satName} variant="outlined" className={classes.formControl}>
                                            <InputLabel id="ground-track-select-label" className={classes.inputLabel}>{`Ground Track: ${satName}`}</InputLabel>
                                            <Select
                                                variant="outlined"
                                                style={{ flex: 1 }}
                                                className={classes.select}
                                                labelId="ground-track-select-label"
                                                id="ground-track-select"
                                                value={groundTrack.hasOwnProperty(satName) && groundTrack[satName]["category"] || ""}
                                                label={`Ground Track: ${satName}`}
                                                onChange={this.handleGroundTrackChange(satName)}
                                                labelplacement="top"
                                                disabled={!isManuallyGenerated}
                                                classes={{
                                                    // select: classes.select
                                                }}>
                                                {Object.keys(GROUND_TRACK_CATEGORIES).map((level) => {
                                                    return (
                                                        <MenuItem key={level} value={level}>
                                                            <ListItemText primary={level} />
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    );
                                })
                            }
                            <FormControl key={humanClassification} variant="outlined" className={classes.formControl}>
                                <InputLabel id="event-category-select-label" className={classes.inputLabel}>{`Human Classification: ${humanClassification}`}</InputLabel>
                                <Select
                                    error={this.state.humanClassificationError}
                                    variant="outlined"
                                    style={{ flex: 1 }}
                                    className={classes.select}
                                    labelId="event-category-select-label"
                                    id="event-category-select"
                                    value={humanClassification}
                                    label={`Human Classification ${humanClassification}`}
                                    onChange={this.handleHumanClassificationChange}
                                    labelplacement="top"
                                    disabled={!isManuallyGenerated && !(AppState.mode === C_MODE.C_EDIT_MODE)} // Enabled if manual input or in edit mode
                                    classes={{
                                        // select: classes.select
                                    }}>
                                    {_humanClassificationCategories.map((category) => {
                                        return (
                                            <MenuItem key={category.name} value={category.name} disabled={!category.view}>
                                                <ListItemText primary={category.name}/>
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.flexItemRow} style={{ borderBottom: 'none' }}>
                            <TextField
                                style={{ flexGrow: 1 }}
                                label="Other Information"
                                multiline
                                rows="2"
                                value={otherInformation}
                                onChange={this.handleChangeTextField('otherInformation')}
                                className={classes.textField}
                                margin="dense"
                                variant={inputModeMap[AppState.mode].variant}
                                InputLabelProps={{
                                    classes: {
                                        outlined: classes.inputLabel
                                    }
                                }}
                                InputProps={{
                                    maxLength: 300,
                                    readOnly: inputModeMap[AppState.mode].readOnly,
                                    startAdornment: (
                                        <Select
                                            id='tag-dropdown'
                                            className={classes.select}
                                            onChange={this.handleAddTag}
                                            label="Tag"
                                            variant="outlined"
                                            style={{ flex: 1 }}
                                            labelplacement="top"
                                            value=''
                                        >
                                        {tags.map((tag, index) => {
                                            return (
                                                <MenuItem key={`${tag.name}-${index}`} value={tag.name}>
                                                    <Checkbox checked={this.state.otherInformation.indexOf(tag.name) > -1}/>
                                                    <ListItemText primary={tag.name}/>
                                                </MenuItem>
                                            );
                                        })}
                                        </Select>
                                    ),
                                }}
                            />
                            <TextField
                                style={{ flex: 1 }}
                                label="Other Sources"
                                className={classes.textField}
                                value={otherDetectingSources}
                                onKeyUp={this.handleKeyUp('otherDetectingSources')}
                                onChange={this.handleChangeTextField('otherDetectingSources')}
                                margin="dense"
                                variant={inputModeMap[AppState.mode].variant}
                                InputLabelProps={{
                                    classes: {
                                        outlined: classes.inputLabel
                                    }
                                }}
                                InputProps={{
                                    inputProps: { maxLength: 50 },
                                    readOnly: inputModeMap[AppState.mode].readOnly
                                }}
                            />
                        </div>
                    </div>
                    }
                    {isManuallyGenerated === true && (AppState.mode === C_MODE.C_EDIT_MODE || AppState.mode === C_MODE.C_ADD_MODE) &&
                        <FileUpload error={eventAttachmentsError} disabled={fileUploadButtonDisabled} getFormData={this.getFormData} onSuccess={this.onFileuploadSuccess} onError={this.onFileuploadError} />
                    }
                    {(eventAttachments.length > 0) &&
                    <Accordion expanded={this.state.accordionExpanded} sx={{backgroundColor: this.state.worldMapAccordionBC, margin: '10px'}} onChange={() => this.state.worldMapAccordionBC === '#ECE6DE' ? this.setState({worldMapAccordionBC: 'white', accordionExpanded: true}) : this.setState({worldMapAccordionBC: '#ECE6DE', accordionExpanded: false})}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>Location/Light Curve</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EventFormAttachments getInstance={this.onGetEventFormAttachmentsInstance} eventAttachments={eventAttachments} eventRefreshAttachments={eventRefreshAttachments} getFormData={this.getFormData} isManuallyGenerated={this.state.isManuallyGenerated} status={eventStatus}/>
                        </AccordionDetails>
                    </Accordion>   
                    }
                    {pdfFilenames && pdfFilenames.map((pdfFilename, index) => {
                        return (
                            <Accordion defaultExpanded expanded={this.state.validationReportExpanded} sx={{backgroundColor: this.state.pdfAccordionBC, margin: '10px'}} onChange={() => this.state.validationReportExpanded ? this.setState({pdfAccordionBC: '#ECE6DE', validationReportExpanded: false}) : this.setState({pdfAccordionBC: 'white', validationReportExpanded: true})}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography>Validation Report     {index+1} of {pdfFilenames.length} </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <PDFViewer file={pdfFilename} classes={classes}/>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })   
                    }
                    <div>&nbsp;</div>
                </div>
            </div>
        );
    }
}

EventFormEntry.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EventFormEntry)
